import React, { Component } from 'react';
import {withRouter } from 'react-router-dom';
import './pconestrella20.css';



class Pconestrella20 extends Component {

    render() {
        return (
            <div >
                <div >

                    <div > <img src={require('../../imagens/5platos1.png')} width="300" height="152" />  </div>
                    <div > <img src={require('../../imagens/5platos2.jpg')} width="180" height="200" />  </div>

                    <h3 className="texto1"> 5platos con Estrella 2020</h3>

                </div>

                <h4 className="texto4">
                    Restaurante .... <br />
                    Barcelona...?
          </h4>

                <div className="container1"  >


                    <div> <img src={require('../../imagens/pconestrella201.jpg')} width="280" height="220" />  </div>
                    <div> <img src={require('../../imagens/pconestrella202.jpg')} width="280" height="220" />  </div>
                    <div> <img src={require('../../imagens/pconestrella203.jpg')} width="280" height="220" />  </div>

                </div >



                <h4 className="texto4">

                    <font color="#b71c1c"> <h1>5platos con Estrella</h1></font>

                </h4>

                <h4 className="texto5">
                    Si as llegado hasta aquí, tienes la posibilidad <br />
                     de participar de una cena experiencia solidaria "exclusiva".<br />
                    <strong>Solo 15 personas</strong> podrán cenar en la misma mesa con el/la  ".........."
                </h4>


                <h4 className="texto5">
                    Para este evento pensamos que el espacio y la comida son importantes,<br />
                por eso nos hemos decidido por el restaurante "......." de "..........." ,<br />
                un lugar precioso , intimo y acogedor.
                </h4>

                <h4 className="texto5">
                    Su excelente equipo de cocina ha creado para esta ocasión un increíble menú ,<br />
                acompañado de unos <strong>excelentes vinos, cavas y bebidas varias.</strong>
                </h4>

                <h4 className="texto5">
                    Igual de importante para nosotros, es la parte solidaria,<br />
                por eso <strong>todos los beneficios</strong> de esta cena irán a la <strong>ONG ARKsocial</strong>. <br />
                Para seguir adelante con sus proyectos y poder ayudar a más personas.
                </h4>

                <h4 className="texto5">
                    Estamos seguros de que con este ambiente y propósito tan especial, <br />
                    surgirán situaciones y momentos únicos e inolvidables.
                </h4>

                <h4 className="texto5">
                    Te esperamos el ...
                </h4>

                <h4 className="texto5">
                    <strong> ¿Quieres ser una de las 15 personas en vivir esta experiencia ?</strong>
                </h4>

                <h4 className="texto5">
                    <strong> ¡No te lo pierdas, plazas muy limitadas !</strong>
                </h4>

                <h4 className="sublinhado">
                    Precio:
                </h4>

                <h4 className="texto5">
                    *La compra del ticket no garantiza la asistencia al evento.  <br />
                    Es necesaria confirmación via email, wassap o por teléfono.
                </h4>

                <h4 className="texto5">
                    * * * * *
                </h4>

                <h4 className="sublinhado">
                    <strong> Venta tickets:</strong>
                </h4>

                <h4 className="texto5">
                    <strong> Transferencia bancaria:</strong>
                </h4>

                <h4 className="texto5">
                    BBVA 0182 6260 0002 0170 5780 - IBAN : ES38 <br />
                (poner: 5platos Estrella20 + nombre y apellidos)
                </h4>

                <h4 className="texto5">

                    <strong>FILA 0 :</strong> <br />
                BBVA 0182 6260 0002 0170 5780 - IBAN : ES38
                </h4>

                <h4 className="texto5">
                    ____
                </h4>

                <h4 className="texto5">
                    <strong>Contacto: </strong>info@5platos.com / Tel. 622324072
                </h4>

                <h4 className="texto5">
                    * * * * *
                </h4>

            </div >


        );
    }
}

export default withRouter(Pconestrella20)