import React from 'react';
import './footer.css';


function Footer() {
  return (
    <div className="body">  

      <div className="galeria">
        <img className="foto" src={require('../../imagens/1.png')} />
        <img className="foto" src={require('../../imagens/2.png')} />
        <img className="foto" src={require('../../imagens/3.jpg')} />
        <img className="foto" src={require('../../imagens/4.jpg')} />
        <img className="foto" src={require('../../imagens/5.jpg')} />
        <img className="foto" src={require('../../imagens/6.jpg')} />
        <img className="foto" src={require('../../imagens/7.jpg')} />
        <img className="foto" src={require('../../imagens/8.jpg')} />

      </div>

    </div>

  );
}

export default Footer;