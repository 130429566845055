import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';


import Home from './components/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Quehacemos from './components/Quehacemos';
import Como from './components/Como';
import Contacto from './components/Contacto';
import Pconestrella20 from './components/Pconestrella20';

import './global.css';


class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Header />
        <Switch>

          <Route exact path="/" component={Home} />
          <Route exact path="/quehacemos" component={Quehacemos} />
          <Route exact path="/como" component={Como} />
          <Route exact path="/pconestrella20" component={Pconestrella20} />
          <Route exact path="/contacto" component={Contacto} />

        </Switch>
        <Footer />
      </BrowserRouter>

    );
  }

}



export default App;
