import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './quehacemos.css';



class Quehacemos extends Component {

  render() {
    return (
      <div >
        <div >

          <div > <img src={require('../../imagens/5platos1.png')} width="300" height="152" />  </div>
          <div > <img src={require('../../imagens/5platos2.jpg')} width="180" height="200" />  </div>

          <h3 className="texto1"> Qué hacemos ?</h3>
          <h4 className="texto3">
            A través de diferentes iniciativas y acciones, utilizamos la gastronomia <br />
            para crear experiencias solidarias únicas.<br />
            Con el fin de conseguir recursos <br />
            para proyectos sociales.
          </h4>

        </div>

        <div className="container1"  >

          <div className="container2">

            <div> <h2 className="texto1">5platos +</h2> </div>
            <div> <img src={require('../../imagens/quehacemos1.jpg')} width="280" height="220" />  </div>
            <div>   <h4 className="texto2" >
              Organizamos talleres, cursos, demostraciones, colaboraciones, presentaciones gastronomicas, etc.
              Para niños, jovenes, colegios, hospitales, centros especiales, en general para otros proyectos que tengan interés de
              utilizar la gastronomia como instrumento de inclusión social, entretenimiento, formación....
              </h4>
            </div>
          </div>

          <div className="container2">
            <div><h2 className="texto1">5platos experiences</h2>  </div>
            <div> <img src={require('../../imagens/quehacemos2.jpg')} width="280" height="220" />  </div>
            <div> <h4 className="texto2">
              Es una cena solidaria, una suma de talentos, dónde mezclamos  5 elementos y
              ofrecemos la oportunidad de vivir una experiencia solidaria única.<br />
              <br />
              1- Solidaridad.<br />

              2- Gastronomia.<br />

              3- Música.<br />

              4- Sorpresas.<br />

              5- Espectaculo.
              </h4>
            </div>
          </div>

          <div className="container2">
            <div> <h2 className="texto1" >5platos IN</h2></div>
            <div> <img src={require('../../imagens/quehacemos3.jpg')} width="280" height="220" />  </div>
            <div>
              <h4 className="texto2">
                IN, de INiciativas. Son iniciativas procreadas junto con nuestros colaboradores.
                En las que   ofrecen, una cena, una comida, un plato, un producto , una participación...creando
                un momento consciente-magico, entre gastronomia y solidaridad. Y puedan sentirse parte del movimiento 5 platos.
              </h4>
            </div>
          </div>


        </div >



      </div >


    );
  }
}

export default withRouter(Quehacemos)