import React, { Component } from 'react';
import './home.css'

class Home extends Component {

  render() {
    return (
      <div className="imagens">

        <div > <img src={require('../../imagens/5platos1.png')} width="300" height="152" />  </div>
        <div > <img src={require('../../imagens/5platos2.jpg')} width="355" height="390" />  </div>

        <h4 className="texto1">

          <p>5platos, es un movimiento solidario, <br />
         que utiliza la gastronomia para conseguir recursos,<br />
         para proyectos sociales en Brasil.</p></h4>

      </div>


    );
  }
}

export default Home;
