import React from 'react';
import { Link } from 'react-router-dom';
import './header.css';


function Header() {
  return (
    <header id="main-header">
      <div className="header-content">
        <Link to="/">
          Inicio
        </Link>
        <Link to="/quehacemos">
          Qué hacemos
        </Link>
        <Link to="/como">
          Cómo
        </Link>
        <Link to="/pconestrella20">
          5PconEstrella20
        </Link>
        <Link to="/contacto">
          Contacto
        </Link>

      </div>
    </header>
  );
}

export default Header;