import React, { Component } from 'react';
import {withRouter } from 'react-router-dom';
import './como.css';



class Como extends Component {

    render() {
        return (

            <div >
                <div >

                    <div > <img src={require('../../imagens/5platos3.png')} width="280" height="220" />  </div>


                    <h4 className="texto3">
                        Junto a los mejores profesionales, sumando talentos, <br />
                        buscando siempre la excelencia y ofrecer lo mejor <br />
                        al servicio de la solidaridad.
                    </h4>

                </div>

                <div className="container1"  >

                    <div className="container2">

                        <div> <h2 className="texto1">Cómo?</h2> </div>
                        <div> <img src={require('../../imagens/como1.jpg')} width="280" height="220" />  </div>
                        <div>   <h4 className="texto2" >
                            Realizamos eventos, cenas, comidas, platos, productos, talleres...etc.
                            En colaboración con cocineros, chefs, empresas, profesionales y amantes de la gastronomia.
                            Ofreciendo lo mejor de corazón y en excelencia.
                  </h4>
                        </div>
                    </div>

                    <div className="container2">
                        <div><h2 className="texto1">Para?</h2>  </div>
                        <div> <img src={require('../../imagens/como2.jpg')} width="280" height="220" />  </div>
                        <div> <h4 className="texto2">
                            Para dar a conocer la gastronomia, crear  una experiencia única,pasarlo bien
                            y sobretodo que la gastronomia sea utilizada como instrumento para conseguir
                            recursos para proyectos sociales.
                  </h4>
                        </div>
                    </div>

                    <div className="container2">
                        <div> <h2 className="texto1" >Dónde?</h2></div>
                        <div> <img src={require('../../imagens/como3.jpg')} width="280" height="220" />  </div>
                        <div> <h4 className="texto2">
                            En cualquier lugar que surja la oportunidad de tener un espacio y profesionales con ganas y voluntad de ser parte.
                            Especialmente restaurantes, hoteles, escuelas gastronomicas, espacios habitilados...etc
                  </h4>
                        </div>
                    </div>


                </div >

            </div >

        );
    }
}

export default withRouter(Como)