import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './contacto.css';
import './index.php';


class Contacto extends Component {

    render() {
        return (

            <section>
                <div className="contato">
                    <h3>
                        info@5platos.com
                    </h3>

                    <form className="form" method="post" action="contact.php">

                        <input className="field" name="nombre" placeholder="Nombre*" />
                        <input className="field" name="apellidos" placeholder="Apellidos*" />
                        <input className="field" name="email" placeholder="E-mail*" />
                        <input className="field" name="telefono" placeholder="Teléfono" />
                        <input className="field" name="pais" placeholder="Pais" />
                        <textarea className="textarea" name="mensaje" placeholder="Mensaje*" />
                        <input type="submit" value="Enviar formulario" />

                    </form>
                    <h4>Atención: Los espacios marcados con * son obligatorios.</h4>
                    <h4>Por favor, tenga en cuenta que el contenido de este formulario no está encriptado.</h4>

                </div>
            </section>
        );
    }
}


export default withRouter(Contacto)